import Banner from './banner.vue'
import BillBoard from './billboard.vue'
import Video from './video.vue'
import customNative from './customNative.vue'
import carousel from './carousel.vue'
import display from './display.vue'


window.FPI.extension.register("#banner", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element, 
      component: Banner
    });
  }
})

window.FPI.extension.register("#billboard", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element, 
      component: BillBoard
    });
  }
})

window.FPI.extension.register("#video", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element, 
      component: Video
    });
  }
})

window.FPI.extension.register("#customNative", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element, 
      component: customNative
    });
  }
})

window.FPI.extension.register("#carousel", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element, 
      component: carousel
    });
  }
})

window.FPI.extension.register("#display", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element, 
      component: display
    });
  }
})