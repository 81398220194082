<template>
  <div>
    <div v-if="msite">
      <ins id="uid4" data-adspot-key="sg2joahn" data-ad-sizes="320x45" data-source="com.jio.sample" data-customization="set-ad-data" click-element="customimageuyev13p0" data-refresh-rate="30"> 
        <div id="div_parent" style="display: block; width: 100%; height: 100%;">
            <img data-native-element="customimage" class="customimageuyev13p0" style="display: block; width: 100%;"/>
        </div>
      </ins>
    </div>
    <div v-else>
      <ins id="uid4" data-adspot-key="e43zb8a7" data-ad-sizes="1680x320" data-source="com.jio.sample" data-customization="set-ad-data" click-element="customimageuyev13p0" data-refresh-rate="30"> 
        <div id="div_parent" style="display: block; width: 100%; height: 100%;">
            <img data-native-element="customimage" style="display: block; width: 100%;" class="customimageuyev13p0"/>
        </div>
      </ins>
    </div>
  </div>
</template>


<script>
export default {
  name: 'customNative',

  data() {
    var ua = navigator.userAgent;
    var msite = false;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        msite = true;
        console.log("in custom native detected as tablet msite");
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        console.log("in custom native detected as mobile msite");
        msite = true;
    } else {
        console.log("in custom native detected as desktop");
        msite = false;
    }
    return {
        msite: msite
    }
  },

  async mounted() {
  }

}
</script>


<style scoped>
.mainimageuyev13p0 {
  width: auto;
  height: 300px;
}
</style>