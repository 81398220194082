<template>
  <div>
    <div v-if="showPriceDrop">
      <ins id="uid3" data-container-id="instreamContainer" data-adspot-key="1584c269" data-source="com.jio.sample"></ins>
      <div id="instreamContainer"></div>
    </div>
  </div>
  
</template>


<script>
export default {
  name: 'banner',

  data() {
    return {
      showPriceDrop: true
    }
  },

  async mounted() {
  }

}
</script>


<style scoped>
img {
  height: 48px;
  width: auto;
}
</style>