<template>
  <div>
    <div v-if="showPriceDrop">
      <ins id="uid6" data-adspot-key="ls2xy7to" data-source="com.jio.sample"></ins>
    </div>
  </div>
  
</template>


<script>
export default {
  name: 'display',

  data() {
    return {
      showPriceDrop: true
    }
  },

  async mounted() {
  }

}
</script>


<style scoped>
</style>